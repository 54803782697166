import React, { useState } from 'react';
import { Row, Col, button } from 'antd';
import { Link } from 'react-router-dom';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Errores from '../../paginas/Errores';
import '../../../assets/css/home.css';
import { guardar_mail } from '../../../utils/ConexionServidor';
import axios from "axios";

import { useHistory } from "react-router-dom";


import img_capitulo_1 from '../../../assets/images/sitio/banner/banner_compendioCAP1_.jpg';
import img_capitulo_2 from '../../../assets/images/sitio/banner/banner_compendiocap2_.jpg';
import img_capitulo_3 from '../../../assets/images/sitio/banner/banner_compendioC3_.jpg';
import img_capitulo_4 from '../../../assets/images/sitio/banner/banner_compendioC4.jpg';
import img_capitulo_5 from '../../../assets/images/sitio/banner/banner_compendioC5_.jpg';
import img_capitulo_6 from '../../../assets/images/sitio/banner/banner_compendioC6.jpg';
import img_capitulo_7 from '../../../assets/images/sitio/banner/banner_compendioC7.jpg';
import img_capitulo_8 from '../../../assets/images/sitio/banner/banner_compendioC8.jpg';
import img_completo from '../../../assets/images/sitio/banner/banner_compendio_completo_.jpg';

import { Element } from 'react-scroll';

const BannerCapitulos = () => {

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 6000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    };
    return (
        <>
       { /**<Slider {...settings} style={{marginTop:'0px', paddingtop:'0px',marginBottom:'-6px', paddingBotton:'0px'}}>
        <div>
            <Link to="/compendio-estadistico-completo"><img src={img_completo} style={{ width: '100%' }} alt="Compendio" /></Link>
          </div>
        <div>
          <Link to="/compendio-estadistico-capitulo-8"><img src={img_capitulo_8} style={{ width: '100%' }} alt="capitulo 8" /></Link>
          </div>
    
          
    </Slider>*/}
    <Link to="/compendio-estadistico-completo"><img src={img_completo} style={{ width: '100%' }} alt="Compendio" /></Link>
        </>
    );
}

export default BannerCapitulos;
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col, Drawer} from 'antd';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';

import '../../../assets/css/header.css';

import social_mail from '../../../assets/images/sitio/HEADER/icono_email_am.png';
import social_telefono from '../../../assets/images/sitio/HEADER/icono_tel_am.png';
import social_wsp from '../../../assets/images/sitio/HEADER/icono_whats_am.png';

import social_wsp_blc from '../../../assets/images/sitio/HEADER/whatsapp_bco.png';
import social_mail_blc from '../../../assets/images/sitio/FOOTER/icono_mail.png';
import social_telefono_blc from '../../../assets/images/sitio/FOOTER/icono_tel.png';

import wsp_bl from '../../../assets/images/sitio/HEADER/industriales_unidos.jpeg';

import social_facebook_am from '../../../assets/images/sitio/HEADER/iconofb_am.png';
import social_facebook_bl from '../../../assets/images/sitio/HEADER/iconofb_bco.png';
import social_twitter_am from '../../../assets/images/sitio/HEADER/iconott_am.png';
import social_twitter_bl from '../../../assets/images/sitio/HEADER/iconott_bco.png';
import social_youtube_am from '../../../assets/images/sitio/HEADER/iconoyoutube_am.png';
import social_youtube_bl from '../../../assets/images/sitio/HEADER/iconoyoutube_bco.png';

import logo_canimolt from '../../../assets/images/sitio/HEADER/logo_canimolt_color.png';

const menu = [
  { url: '/', seccion: 'INICIO' },
  { url: '/conocenos', seccion: 'CONÓCENOS' },
  { url: '/cereales', seccion: 'CEREALES' },
  { url: '/trigo', seccion: 'TRIGO' },
  { url: '/harina', seccion: 'HARINA' },
  { url: '/productos-socios', seccion: 'PRODUCTOS SOCIOS' },
  { url: '/cadenas-productivas', seccion: 'CEDENAS PRODUCTIVAS' },
  { url: '/publicaciones', seccion: 'PUBLICACIONES' },
  { url: '/blog', seccion: 'BLOG' },
  { url: '/contacto', seccion: 'CONTACTO' },
];

const Header = () => {
  const [mostrar_fb_amarillo, mostrarFbAmarillo] = useState(false);
  const [mostrar_tw_amarillo, mostrarTwAmarillo] = useState(false);
  const [mostrar_yt_amarillo, mostrarYtAmarillo] = useState(false);

  const [mostrar_conocenos, mostrarConocenos] = useState(false);
  const [mostrar_cereales, mostrarCereales] = useState(false);
  const [mostrar_trigo, mostrarTrigo] = useState(false);
  const [mostrar_harina, mostrarHarina] = useState(false);
  const [mostrar_productos_socios, mostrarProductosSocios] = useState(false);
  const [mostrar_cadenas, mostrarCadenas] = useState(false);
  const [mostrar_publicaiones, mostrarPublicaciones] = useState(false);

  const [ver_menu_movil, verShowMenuMovil] = useState(false);

  let location = useLocation();

  let menu_movil = menu.map(item => {
    return (
        <p className="contendor_link_movil" ><Link to={item.url} className="link_item_menu_movil" onClick={() => { verShowMenuMovil(false) }}>{item.seccion.toUpperCase()}</Link></p>
    );
});

  return (
    <>
    <Row justify="center" align="middle">
      <Col xs={0} lg={24}>
        <Row justify="space-around" align="middle" className="back_negro">
          <Col xs={0} md={{span:14, offset:2}}>
            <Row>
              <Col span={7} offset={1}>
                <a href="mailto:harinadetrigo@canimolt.org" target="_blank" className="link_red_social"><img alt="" src={social_mail} className="img_red_social_text" />harinadetrigo@canimolt.org</a>
              </Col>
              <Col span={7} offset={1}>
                <a href="tel:+525555232387" target="_blank" className="link_red_social"><img alt="" src={social_telefono} className="img_red_social_text" />+52 (55) 5523 2387</a>
              </Col>
              <Col span={7} offset={1}>
                <a href="https://api.whatsapp.com/send?phone=525555232387&text=Hola" target="_blank" className="link_red_social"><img alt="" src={social_wsp} className="img_red_social_text" />+52 (55) 5523 2387</a>
              </Col>
            </Row>
        </Col>
        <Col span={8}>

          <a href="https://twitter.com/CANIMOLT" target="_blank" className="link_red_social" onMouseEnter={() => mostrarTwAmarillo(true)} onMouseLeave={() => mostrarTwAmarillo(false)}>
            <img alt="" src={mostrar_tw_amarillo ? social_twitter_am : social_twitter_bl} className="img_red_social" /></a>
          <a href="https://www.youtube.com/channel/UCtGtuXiNFhmwyqMWm1e0Axw?view_as=subscriber" target="_blank" className="link_red_social" onMouseEnter={() => mostrarYtAmarillo(true)} onMouseLeave={() => mostrarYtAmarillo(false)}>
            <img alt="" src={mostrar_yt_amarillo ? social_youtube_am : social_youtube_bl} className="img_red_social" /></a>
        </Col>
        </Row>
      </Col>
      <Col xs={0} lg={24}>
        <Row justify="center" align="middle">
        
          <Col span={3} offset={1}>
            <Link to="/"><img alt="" src={logo_canimolt} className="logo" /></Link>
          </Col>
          <Col span={20}>
            <Row justify="center" align="middle">
            <Col className="contenedor_seccion_menu" >
              <p className={location.pathname === '/' ? 'contenedor_menu_select' : 'contenedor_menu'}><Link to="/" className={location.pathname === '/' ? 'link_menu_selec' : 'link_menu'}>INICIO</Link></p>
            </Col>
            <Col className="contenedor_seccion_menu"  onMouseEnter={ () => mostrarConocenos(true)} onMouseLeave={() => mostrarConocenos(false)} >
            <p className={location.pathname === '/conocenos' ? 'contenedor_menu_select' : 'contenedor_menu'}><Link to="/conocenos" className={location.pathname === '/conocenos' ? 'link_menu_selec' : 'link_menu'}>CONÓCENOS</Link></p>
            {mostrar_conocenos && (
                  <Row className="contenedor_submenu_row">
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/conocenos#mision" className="link_submenu">MISIÓN</Link></p>
                    </Col>
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/conocenos#vision" className="link_submenu">Visión</Link></p>
                    </Col>
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/conocenos#quienes-somos" className="link_submenu">¿Quiénes somos?</Link></p>
                    </Col>
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/conocenos#catalogo" className="link_submenu">Catálago de marcas</Link></p>
                    </Col>
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/conocenos#consejo" className="link_submenu">Consejo directivo</Link></p>
                    </Col>
                  </Row>
                )}
            </Col>
            <Col className="contenedor_seccion_menu" onMouseEnter={ () => mostrarCereales(true)} onMouseLeave={() => mostrarCereales(false)} >
                <p className={location.pathname === '/cereales' ? 'contenedor_menu_select' : 'contenedor_menu'} >
                <Link to="/cereales" className={location.pathname === '/cereales' ? 'link_menu_selec' : 'link_menu'}>CEREALES</Link></p>
                {mostrar_cereales && (
                  <Row className="contenedor_submenu_row">
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/cereales#que-son" className="link_submenu">¿QUÉ SON LOS CEREALES?</Link></p>
                    </Col>
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/cereales#propiedades" className="link_submenu">PROPIEDADES ALIMENTICIAS</Link></p>
                    </Col>
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/cereales#nutricion" className="link_submenu">NUTRICIÓN</Link></p>
                    </Col>
                  </Row>
                )}
                
              </Col>
            <Col className="contenedor_seccion_menu"  onMouseEnter={ () => mostrarTrigo(true)} onMouseLeave={() => mostrarTrigo(false)}>
                <p className={location.pathname === '/trigo' ? 'contenedor_menu_select' : 'contenedor_menu'}>
                <Link to="/trigo" className={location.pathname === '/trigo' ? 'link_menu_selec' : 'link_menu'}>TRIGO</Link></p>
                {mostrar_trigo && (
                  <Row className="contenedor_submenu_row">
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/trigo#definicion" className="link_submenu">DEFINICIÓN DEL TRIGO</Link></p>
                    </Col>
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/trigo#morfologia" className="link_submenu">MORFOLOGÍA DE LA PLANTA</Link></p>
                    </Col>
                    <Col span={24}>
                    <p className="contenedor_submenu"><Link to="/trigo#estructura" className="link_submenu">ESTRUCTURA DEL GRANO</Link></p>
                    </Col>
                    
                    <Col span={24}>
                    <p className="contenedor_submenu"><Link to="/trigo#tipos" className="link_submenu">TIPOS DE GRANO</Link></p>
                    </Col>
                  </Row>
                )}
                
              </Col>
              <Col className="contenedor_seccion_menu"  onMouseEnter={ () => mostrarHarina(true)} onMouseLeave={() => mostrarHarina(false)}>
                <p className={location.pathname === '/harina' ? 'contenedor_menu_select' : 'contenedor_menu'}>
                <Link to="/harina" className={location.pathname === '/harina' ? 'link_menu_selec' : 'link_menu'}>HARINA</Link></p>
                {mostrar_harina && (
                  <Row className="contenedor_submenu_row">
                    <Col span={24}>
                    <p className="contenedor_submenu"><Link to="/harina#definicion" className="link_submenu">DEFINICIÓN DE HARINA</Link></p>
                    </Col>
                    
                    <Col span={24}>
                    <p className="contenedor_submenu"><Link to="/harina#historia" className="link_submenu">HISTORIA DE LA MOLIENTA</Link></p>
                    </Col>
                    <Col span={24}>
                    <p className="contenedor_submenu"><Link to="/harina#clasificacion" className="link_submenu">CLASIFICACIÓN Y USO</Link></p>
                    </Col>
                    <Col span={24}>
                    <p className="contenedor_submenu"><Link to="/harina#cualidades" className="link_submenu">CUALIDADES NUTRIMENTALES</Link></p>
                    </Col>
                  </Row>
                )}
                
              </Col>
              <Col className="contenedor_seccion_menu" onMouseEnter={ () => mostrarProductosSocios(true)} onMouseLeave={() => mostrarProductosSocios(false)}>
                <p className={location.pathname === '/productos-socios' ? 'contenedor_menu_select' : 'contenedor_menu'} >
                <Link to="/productos-socios" className={location.pathname === '/productos-socios' ? 'link_menu_selec' : 'link_menu'}>PRODUCTOS SOCIOS</Link></p>
                {mostrar_productos_socios && (
                  <Row className="contenedor_submenu_row_prodcutos">
                    <Col span={12}>
                    <p className="contenedor_submenu"><Link to="/productos-socios#anahuac" className="link_submenu">HARINERA ANÁHUAC</Link></p>
                    </Col>
                    <Col span={12}>
                    <p className="contenedor_submenu"><Link to="/productos-socios#chiapas" className="link_submenu">HARINERA DE CHIAPAS</Link></p>
                    </Col>
                    <Col span={12}>
                    <p className="contenedor_submenu"><Link to="/productos-socios#deusto" className="link_submenu">MOLINO DEUSTO</Link></p>
                    </Col>
                    <Col span={12}>
                    <p className="contenedor_submenu"><Link to="/productos-socios#elizondo" className="link_submenu">HARINAS ELIZONDO</Link></p>
                    </Col>
                    <Col span={12}>
                    <p className="contenedor_submenu"><Link to="/productos-socios#guadalupe" className="link_submenu">HARINERA GUADALUPE</Link></p>
                    </Col>
                    <Col span={12}>
                    <p className="contenedor_submenu"><Link to="/productos-socios#ho" className="link_submenu">HARINERA DE ORIENTE</Link></p>
                    </Col>
                    <Col span={12}>
                    <p className="contenedor_submenu"><Link to="/productos-socios#tlanepantla" className="link_submenu">HARINERA TLANEPANTLA</Link></p>
                    </Col>
                    <Col span={12}>
                    <p className="contenedor_submenu"><Link to="/productos-socios#trimex" className="link_submenu">GRUPO TRIMEX</Link></p>
                    </Col>
                    <Col span={12}>
                    <p className="contenedor_submenu"><Link to="/productos-socios#moderna" className="link_submenu">GRUPO LA MODERNA</Link></p>
                    </Col>
                    <Col span={12}>
                    <p className="contenedor_submenu"><Link to="/productos-socios#real_altena" className="link_submenu">REAL ALTEÑA </Link></p>
                    </Col>
                    <Col span={12}>
                    <p className="contenedor_submenu"><Link to="/productos-socios#deusto" className="link_submenu">DEUSTO </Link></p>
                    </Col>
                    <Col span={12}>
                    <p className="contenedor_submenu"><Link to="/productos-socios#parayas" className="link_submenu">PARAYAS </Link></p>
                    </Col>
                  </Row>
                )}
                </Col>
              <Col className="contenedor_seccion_menu" onMouseEnter={ () => mostrarCadenas(true)} onMouseLeave={() => mostrarCadenas(false)}>
                <p className={location.pathname === '/cadenas-productivas' ? 'contenedor_menu_select' : 'contenedor_menu'} >
                <Link to="/cadenas-productivas" className={location.pathname === '/cadenas-productivas' ? 'link_menu_selec' : 'link_menu'}>CADENAS PRODUCTIVAS</Link></p>
                {mostrar_cadenas && (
                  <Row className="contenedor_submenu_row">
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/cadenas-productivas#panificacion" className="link_submenu">PANIFICACIÓN</Link></p>
                    </Col>
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/cadenas-productivas#semola-pasta" className="link_submenu">SÉMOLA Y PASTA</Link></p>
                    </Col>
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/cadenas-productivas#salvado-germen" className="link_submenu">SALVADO Y GERMEN</Link></p>
                    </Col>
                  </Row>
                )}
                </Col>
              <Col className="contenedor_seccion_menu" onMouseEnter={ () => mostrarPublicaciones(true)} onMouseLeave={() => mostrarPublicaciones(false)}>
              <p className={location.pathname === '/publicaciones' ? 'contenedor_menu_select' : 'contenedor_menu'}><Link to="/publicaciones" className={location.pathname === '/publicaciones' ? 'link_menu_selec' : 'link_menu'}>PUBLICACIONES</Link></p>
              {mostrar_publicaiones && (
                  <Row className="contenedor_submenu_row">
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/publicaciones#revistas" className="link_submenu">REVISTAS</Link></p>
                    </Col>
                    <Col span={24}>
                      <p className="contenedor_submenu"><Link to="/publicaciones#reportes" className="link_submenu">REPORTES</Link></p>
                    </Col>
                  </Row>
                )}
              </Col>
              <Col className="contenedor_seccion_menu" >
              <p className={location.pathname === '/blog' ? 'contenedor_menu_select' : 'contenedor_menu'}><Link to="/blog" className={location.pathname === '/blog' ? 'link_menu_selec' : 'link_menu'}>BLOG</Link></p>
              </Col>
              <Col className="contenedor_seccion_menu" >
              <p className={location.pathname === '/contacto' ? 'contenedor_menu_select' : 'contenedor_menu'}><Link to="/contacto" className={location.pathname === '/contacto' ? 'link_menu_selec' : 'link_menu'}>CONTACTO</Link></p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={{span:8}} lg={0}>
            <Link to="/"><img alt="" src={logo_canimolt} className="logo" /></Link>
          </Col>
      <Col xs={24} lg={0}>
        <Row align="middle" className="contenedor_header_movil">
          <Col span={2} offset={1}>
            <a hfre="mailto:harinadetrigo@canimolt.org"><img alt="" src={social_mail_blc} className="logo_movil"/></a>
          </Col>
          <Col span={2}>
            <a hfre="tel:5555232387"><img alt="" src={social_telefono_blc} className="logo_movil"/></a>
          </Col>
          <Col span={2}>
            <a hfre="https://api.whatsapp.com/send?phone=5215555232387&text=Hola"><img src={social_wsp_blc} className="logo_movil"/></a>
          </Col>
          
          <Col span={2}>
            <a hfre="https://twitter.com/CANIMOLT"><img alt="" src={social_twitter_bl} className="logo_movil"/></a>
          </Col>
          <Col span={2}>
            <a hfre="https://www.youtube.com/channel/UCtGtuXiNFhmwyqMWm1e0Axw?view_as=subscriber"><img alt="" src={social_youtube_bl} className="logo_movil"/></a>
          </Col>

          <Col span={7} offset={5} className="contenedor_icono_hamburguesa">
            <MenuOutlined className="icono_movil" onClick={() => { verShowMenuMovil(!ver_menu_movil) }} />
          </Col>
        </Row>
        <Row style={{position:'relative'}}>
          {ver_menu_movil &&
            <Col className="col_menu_movil">
            {menu_movil}
            </Col>
          }
        </Row>
        
      </Col>
      
    </Row>
   {/*<Row className="content_logo_fixed">
    <Col xs={{span:2, offset:21}} lg={{span:2, offset:21}} >
        <img alt="" src={wsp_bl} className="sticky_logo" />
      </Col>
        </Row>*/}
    </>
  );
}

export default Header;
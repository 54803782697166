const anahuac = [
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/anahuac/ANAHUAC_multiusos.png"), "marca": "Independencia Multiusos", "tipo_harina": "Harina con alto contenido de gluten, muy confiable en procesos mecanizados y semi-mecanizados. Su alto contenido de proteínas, así como su alta absorción, la convierten en una gran solución panadera con excelentes rendimientos. Ideal para pan francés, pan blanco y harinas especializadas. Destaca su comportamiento en hornos con o sin vapor, muy buen volumen de pan y larga vida de anaquel. Es una harina multipropósito.", "usos": "Uso recomendado: Todo tipo de procesos panificables, pan blanco, bollería, telera, bizcochería, feite, danés, pizza etc.", "presentaciones": "10kg 22kg 44kg", "comentarios": "Tipo de proceso: Procesos mecanizados y semimecanizados. Harina multipropósito.", "url": "www.harineraanahuac.com"}, 
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/anahuac/ANAHUAC_superindependencia.png"), "marca": "Súper Independencia", "tipo_harina": "Harina de fuerza media, ideal para procesos artesanales y semimecanizados, que otorga gran textura y sabor a variedades de pan blanco y bizcochería", "usos": "", "presentaciones": "44kg.", "comentarios": "Tipo de proceso: Harina de fuerza media ideal para prosesos semi mecanizados", "url": "www.harineraanahuac.com"}, 
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/anahuac/ANAHUAC_independecia_maxima.png"), "marca": "Maxima", "tipo_harina": "Harina con excelente tolerancia a la fermentación, su alto contenido de proteína proporciona propiedades ideales de textura, desarrollo y excelente volumen en variedades como pan blanco y bizcocho.", "usos": "Uso recomendado: Pan blanco, bollería, telera.", "presentaciones": "10kg. 44kg.", "comentarios": "Tipo de proceso: Tolerancia a la fermentación, procesos semi-mecanizados.", "url": "www.harineraanahuac.com"}, 
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/anahuac/ANAHUAC_independeciatortilla.png"), "marca": "Independencia Tortilla", "tipo_harina": "Harina con gran fuerza y con la extensibilidad ideal, apreciada por su alto grado de blancura, para obtener masas con un laminado excelente y absorción de agua superior. Tiene el mejor rendimiento en piezas, así como textura y vida de anaquel.", "usos": "Bizcochería, churros.", "presentaciones": "44kg.", "comentarios": "Tipo de proceso: Tolerancia a la fermentación, proceso especializado en tortilla.", "url": "www.harineraanahuac.com"}
];

const chiapas = [
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/chiapas/chiapas_sancristobalplus.png"), "marca": "Harina San Cristóbal Plus", "tipo_harina": "Harina de Trigo Extrafuerte y Alta en Proteína.", "usos": "Ideal para la elaboración de bolillos, teleras, pan de caja, bollos, bizcochos, hojaldres,conchas y gran variedad de pan dulce.", "presentaciones": "44kg. 22kg.", "comentarios": "", "url":"" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/chiapas/chiapas_flordechiapas_trigofuerte.png"), "marca": "Harina Flor de Chiapas", "tipo_harina": "Harina de Trigo Fuerte", "usos": "Ideal para la elaboración de bolillos, teleras, pan de caja, bollos, bizcochos, hojaldres,conchas y gran variedad de pan dulce.", "presentaciones": "44kg.", "comentarios": "", "url":"" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/chiapas/chiapas_Montebello_trigoestandar.png"), "marca": "Harina Montebello", "tipo_harina": "Harina de Trigo Estandar", "usos": "Ideal para la elaboración de bizcochería, bollería, pastelería y pan artesanal.", "presentaciones": "44kg.", "comentarios": "", "url":"" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/chiapas/chiapas_aguaazul_trigosuave_44.png"), "marca": "Harina Agua Azul", "tipo_harina": "Harina de Trigo Suave", "usos": "Harina suave especial para la elaboración de pan artesanal, galletas y pizzas.", "presentaciones": "44kg.", "comentarios": "", "url":"" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/chiapas/chiapas_sancristobalTortillas.png"), "marca": "Harina San Cristóbal Tortillas", "tipo_harina": "Harina de Trigo Fuerte", "usos": "Ideal para la elaboración de tortillas de harina.", "presentaciones": "22kg.", "comentarios": "", "url":"" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/chiapas/chiapas_sancrsitobal.png"), "marca": "Harina San Cristóbal", "tipo_harina": "Harina de Trigo Suave", "usos": "Ideal para la elaboración de panes a nivel artesanal y gran variedad de galletas.", "presentaciones": "10kg.", "comentarios": "", "url":"" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/chiapas/chiapas_flordechaiapas_trigosuave_10.png"), "marca": "Harina Flor de Chiapas", "tipo_harina": "Harina de Trigo Suave", "usos": "Ideal para la elaboración de panes a nivel artesanal y gran variedad de galletas.", "presentaciones": "10kg.", "comentarios": "", "url":"" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/chiapas/chiapas_montebello_trigosuave_10.png"), "marca": "Harina Montebello", "tipo_harina": "Harina de Trigo Suave", "usos": "Ideal para la elaboración de panes a nivel artesanal y gran variedad de galletas.", "presentaciones": "10kg.", "comentarios": "", "url":"" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/chiapas/chiapas_aguaazul_trigosuave_10.png"), "marca": "Harina Agua Azul", "tipo_harina": "Harina de Trigo Suave", "usos": "Ideal para la elaboración de panes a nivel artesanal y gran variedad de galletas.", "presentaciones": "10kg.", "comentarios": "", "url":"" }
];

const deusto = [
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/deusto/santarosa_deusto.png"), "marca": "Santa Rosa", "tipo_harina": "Suave", "usos": "Galletas, botanas, polvorones, pastelillos, campechanas.", "presentaciones": "44kg.", "comenbtarios": "", "url": "http://molinodeusto.com.mx/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/deusto/deusto.png"), "marca": "Deusto", "tipo_harina": "Fina", "usos": "Galletas, polvorones, empanadas campechanas, re.postería, churros, pizzas.", "presentaciones": "44 kg. 20 kg. 10 kg. 1 kg.", "comenbtarios": "", "url": "http://molinodeusto.com.mx/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/deusto/reinavictoria_deusto.png"), "marca": "Reina Victoria", "tipo_harina": "Super fina", "usos": "Bizcochería fina, pizzas, panadería artesanal, tortilla, pan blanco, pastelería fina, pan dulce.", "presentaciones": "44kg. 22kg. 20kg. 10kg. 5kg.", "comenbtarios": "", "url": "http://molinodeusto.com.mx/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/deusto/extrafina_salmantina_deusto.png"), "marca": "Salmantina", "tipo_harina": "Extra fina", "usos": "Bolillo, pan danés, hojaldres, pan francés, pastelería especializada y repostería.", "presentaciones": "44kg. 20kg. 10kg. 1kg.", "comenbtarios": "", "url": "http://molinodeusto.com.mx/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/deusto/purepecha_deusto.png"), "marca": "Purépecha Tortilla", "tipo_harina": "Especial para tortilla", "usos": "Tortillas de harina y Buñuelos.", "presentaciones": "44kg, 22kg. 10kg. 5kg.", "comenbtarios": "", "url": "http://molinodeusto.com.mx/"},

    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/deusto/integral_salmantina_deusto.png"), "marca": "La Salmantina Integral", "tipo_harina": "Integral", "usos": "Bolillo, bizcocho, bisquet, baguettes, galletas y tortillas.", "presentaciones": "44kg. 20kg 10kg. 5kg. 1kg.", "comenbtarios": "", "url": "http://molinodeusto.com.mx/"},
    
    
    

];

const elizondo = [
    {"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/hojadeplata_harinadetrigo(1).png"), "marca":"Hoja de Plata", "tipo_harina":"Harina Fuerte, gran poder fermentativo,alto rendimiento,multipropósitos, alto porcentaje de absorció de agua.", "usos":"Todo tipo de procesos para la elaboración de pan, francés, biscocho (bollería), danés, feite (hojaldres, pizzas, galletas,etc.", "presentaciones":"44kg. 22kg. 10kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/maite_harinadetrigo(1).png"), "marca":"Maite", "tipo_harina":"Harina de trigo suave, fermentación corta, muy extensible y muy manejable.", "usos":"Elaboración de varios tipos de galletas, pays, buñuelos, obleas, confitado de cacahuate, etc.", "presentaciones":"44kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/elizondo_harinadetrigo(1).png"), "marca":"Elizondo", "tipo_harina":"Harina  de  trigo  fuerte,  exclusiva  de  la  casa,  alto  poder  fermentativo,  nivel  alto  de  proteína  y  gluten,  alto  rendimiento,    gran  volumen,  alto  porcentaje  de    absorción  de  agua.", "usos":"Industria de panadería mecanizada, francés, bizcocho (bollería), danés, feite (hojaldres), pan de caja, industria de las pizzas, repostería, etc.", "presentaciones":" 44 kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/osasuna_harinadetrigo.png"), "marca":"Osasuna", "tipo_harina":"Harina  de  trigo  fuerte  y  extensible,  muy  blanca  y  fina  (alto  nivel  de  blancura),  masas  elásticas  fáciles  de  laminar,  alto  porcentaje  de  absorción  de  agua,  máximo  rendimiento  de  piezas.", "usos":"La mejor Harina para la elaboración de Tortillas", "presentaciones":"44kg. 22kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/leyre_harinadetrigo(1).png"), "marca":"Leyre", "tipo_harina":"Harina  de  trigo  de  fuerza  intermedia,  extensible  y  manejable.", "usos":"Procesos artesanales y semi mecanizados en la elaboración de pan, francés, bizcocho (bollería), danés, feite (hojaldres), churros y empanadas.", "presentaciones":" 44kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/alaska_harinadetrigo.png"), "marca":"Alaska", "tipo_harina":"  Harina  de  trigo  extensible,  fina,  masas  elásticas,  muy  manejables  y  fáciles    de  laminar,  buen  rendimiento  en  piezas.", "usos":"Elaboración de tortilla y panes planos (base para pizzas, focaccia y pan árabe).", "presentaciones":" 44kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/altaproteina_harinadetrigo(1).png"), "marca":"Alta Proteína", "tipo_harina":"Harina  de  Trigo  con  elevada  fuerza,  máximo  contenido  de  proteína  y  gluten,  máximo  porcentaje  de  absorción  de  agua,  alto  poder  fermentativo,  elevada  absorción  y  rendimiento,  gran  volumen.", "usos":"Panadería industrial, masas congeladas, productos formulados con gran cantidad de grasa y azúcar, base de harinas especiales (mixes), etc.", "presentaciones":" 44kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/nube_harina de trigo.png"), "marca":"Nube", "tipo_harina":"Harina  Clorada,  mayor  blancura  miga  uniforme,  mayor  retención  de  liquido  en  los  mamones  para  la  elaboración  de  pastel  (envinado,  tres  leches,  etc.)", "usos":"Especial para batidos y pastelería.", "presentaciones":" 44kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/unica_extrafina_harinadetrigo(1).png"), "marca":"Única Panadera", "tipo_harina":"Harina  de  trigo  fuerte,  alto  contenido  de  proteína,  gran  capacidad  de  absorción  de  agua,  mayor  volumen,  multipropósitos.", "usos":"Especial para pastelería de alta calidad.", "presentaciones":" 44kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/unica_tortillas.png"), "marca":"Única Tortillera", "tipo_harina":"Harina  de  trigo  fuerte,  excelente  rendimiento,  alto  contenido  de  proteínas,  gran  capacidad  de  absorción  de  agua,  mayor  volumen,  menos  tiempo  de  máquina.", "usos":"Especial para tortillas y pan árabe.", "presentaciones":" 44kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/ensenada_harinadetrigo.png"), "marca":"Ensenada", "tipo_harina":"  Harina  de  trigo  fuerte,  muy  versátil,  alto  poder  fermentativo", "usos":"Especial para pan de molde, bollería, pastelería en general, gran volumen.", "presentaciones":" 44kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/harinaintegral_harinadetrigo.png"), "marca":"Harina integral", "tipo_harina":"Combinación  de  harinas  con  partículas  de  salvadillo.Partículas  irregulares  de  apariencia  de  polvo  semifino,  color  marrón  con  blanco.Sabor  y  olor  característico  al  de  la  harina  de  trigo.Libre  de  sabores  y  olores  extraños.", "usos":"Se usa en la elaboración de todo tipo de panes integrales o de régimen (pan, tortilla, galletas, barras, pizza, barquillos, etc.).", "presentaciones":"44kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/semoladetrigo(1).png"), "marca":"Sémola de trigo", "tipo_harina":"Subproducto  de  la  molienda  de  granos  de  trigo.Se  presenta  en  forma  de  gránulos  pequeños  color  blanco,  ligeramente  amarillentos.Libre  de  olores  a  humedad,  fumigantes  y  otros  olores  extraños.", "usos":"Se utiliza preferentemente como base y mezcla con hojuelas de avena para la preparación de atoles. Para espolvorear base de pizzas y en la elaboración de tapas de empanadas, entre otras aplicaciones.", "presentaciones":"44kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/salvadilloespecial(1).png"), "marca":"Salvadillo especial de Trigo", "tipo_harina":"Subproducto  de  la  molienda  de  trigo  especialmente  seleccionadoSe  presenta  en  forma  de  partículas  irregulares  de  salvado  triturado  y  un  porcentaje  mínimo  de  harina.Color  marrón  con  blanco.Grado  alimenticio.Libre  de  olores  y  sabores  extraños.", "usos":"Principalmente se utiliza en la elaboración de cereales, panecillos, barras y galletas que aporten un alto contenido en fibra.", "presentaciones":"30kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/salvadodetrigo.png"), "marca":"Salvado de trigo", "tipo_harina":"Compuesta  por  Salvado,  cascarilla  y  paja  de  trigo  además  de  otras  semillas  que  son  separadas  durante  la  limpia  y  luego  trituradas  por  un  molino  de  martillos,  dando  una  apariencia  de  partículas  irregulares  color  marrón.", "usos":"Grado Alimenticio es ideal para todo tipo de productos que requieren aporte de fibra, logrando productos de buen color, rico aroma, excelente aspecto y alto contenido de fibra (cereales, galletas, barras y panes integrales).Se utiliza como forraje para animales. Alimento importante para el ganado.", "presentaciones":"44kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/trigorasgado.png"), "marca":"Trigo rasgado", "tipo_harina":"Producto  obtenido  a  partir  de  trigo  seleccionado,  limpio  y  acondicionado.Es  procesado  para  dar  una  apariencia  de  trigo  partido  o  cortado  en  forma  de  láminas  irregulares  con  la  mayor  parte  de  harina  adherida  al  Salvado,  color  marrón  con  blanco.Grado  Alimenticio.Libre  de  olores  a  humedad,  fumigantes  y  otros  olores  extraños.", "usos":"Preferentemente se usa en la elaboración de cereales y barras con alto contenido de fibra; así como en panes integrales o hechos de varios granos, siendo muy apreciado para decorar este tipo de panificados.", "presentaciones":" 40kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/granodetrigo.png"), "marca":"Grano", "tipo_harina":"Formado  por  partículas  de  cascarilla  semi-fino  color  marrón,  y  un  pequeño  porcentaje  de  harina.Es  libre  de  olores  a  humedad,  fumigantes  y  otros  olores  extraños.", "usos":"Grado Alimenticio. Es utilizado para consumo humano para espolvorear charolas.También es utilizado como alimento para animales.", "presentaciones":"35kg.", "comentarios":"", "url":"www.harinaselizondo.com"},
{"img_src": require("../../../../assets/images/sitio/6_productos_socios/elizondo/acemitadetrigo.png"), "marca":"Acemita de trigo", "tipo_harina":"Compuesta por cascarilla y paja de trigo además de otras semillas que son separadas durante la limpia y luego trituradas por un molino de martillos, dando una apariencia de partículas irregulares color marrón.", "usos":"Grado Alimenticio. Es ideal para todo tipo de productos que requieren aporte de fibra, logrando productos de buen color, rico aroma, excelente aspecto y alto contenido de fibra (cereales, galletas, barras y panes integrales).Se utiliza como forraje para animales. Alimento importante para el ganado.", "presentaciones":"28Kg.", "comentarios":"", "url":"www.harinaselizondo.com"}
];

const guadalupe = [
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/guadalupe/guadalupe_dorada.png"), "marca": "Harina Guadalupe Dorada", "tipo_harina": "Harina de Trigo extensible que genera masas suaves y homogéneas. Especial para tortilla de fabricación industrial, con mayor desempeño en el uso artesanal.", "usos": "Ideal para Tortilla de Harina.", "presentaciones": "44kg.", "comentarios": "", "url": "http://www.harinera-guadalupe.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/guadalupe/guadalupe_doradaplus.png"), "marca": "Harina Guadalupe Dorada Plus", "tipo_harina": "Harina de altas especificaciones que garantiza mayor extensibilidad y vida de anaquel; permite una mejor integración de ingredientes. Apta para ser procesada con maquinaria para la elaboración de tortillas. Color aperlado en el producto terminado.", "usos": "Ideal para Tortilla de Harina.", "presentaciones": "44kg.", "comentarios": "", "url": "http://www.harinera-guadalupe.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/guadalupe/guadalupe_doradaextrablanca.png"), "marca": "Harina Guadalupe Dorada Extra Blanca", "tipo_harina": "Harina de Trigo fuerte con alta absorción, extensibilidad y suavidad. Especial para tortilla de uso industrial y artesanal, con estabilidad en cualquier proceso. Alta blancura de color en el producto terminado.", "usos": "Especial para Tortilla de Harina.", "presentaciones": "44kg.", "comentarios": "", "url": "http://www.harinera-guadalupe.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/guadalupe/guadalupe_celio.png"), "marca": "Harina Guadalupe Celio", "tipo_harina": "Harina de Trigo de media fuerza que supera cualquier producto equivalente en su categoría. Ideal para la fabricación de pan artesanal o mecanizado con agradable sabor y consistencia. Ofrece ventajas en materia de absorcióny rendimiento, así como tiempos reducidos de amasado mecanizado o artesanal; y es apta para la elaboración de diferentes masas.", "usos": "Ideal para Bolillo Salado, Telera, Bolillo Fleima, Pan Artesanal, Pan Dulce, entre otros.", "presentaciones": "10kg. 44 kg.", "comentarios": "", "url": "http://www.harinera-guadalupe.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/guadalupe/guadalupe_oro.png"), "marca": "Harina Guadalupe Oro", "tipo_harina": "Harina de Trigo que por sus cualidades, es insuperable por las marcas del segmento. Ofrece ventajas sobre las harinas fuertes que encontramos en el mercado. Garantiza mayor volumen en sus piezas de pan, presentación atractiva y vida de anaquel necesaria para seducir a los panaderos y bizcocheros del país.", "usos": "Ideal para Pan Blanco, Pan Dulce, Baguette y cualquier tipo de Pan de caja.", "presentaciones": "44kg.", "comentarios": "", "url": "http://www.harinera-guadalupe.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/guadalupe/guadalupe_optima.png"), "marca": "Harina Guadalupe Optima", "tipo_harina": "Harina de Trigo de excelentes cualidades que ofrece alto nivel de panificación, fuerza de expansión, gran volumen y mayor vida de anaquel en sus piezas de pan.", "usos": "Ideal para Pan, Pizzas, Pasteles, Pan Blanco, entre otros.", "presentaciones": "1kg. 10kg. 22kg. 44kg.", "comentarios": "", "url": "http://www.harinera-guadalupe.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/guadalupe/guadalupe_jimena.png"), "marca": "Harina Guadalupe Jimena", "tipo_harina": "Harina de Trigo suave de alto valor y prestigio especializada en masas con ingredientes añadidos. Gran versatilidad y rendimiento para el uso en la elaboración de pan dulce y galletería; apta para procesarse manualmente. Garantiza mayor vida de anaquel, suavidad y sabor.", "usos": "Ideal para Pan Dulce, Bizcocho, Pan Danés y Pan de Hojaldre.", "presentaciones": "10kg. 22kg. 44kg.", "comentarios": "", "url": "http://www.harinera-guadalupe.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/guadalupe/guadalupe_panaderia.png"), "marca": "Harina Guadalupe Panadería", "tipo_harina": "Harina de Trigo especializada en volumen, alto rendimiento y pan fino. Por su alto contenido de proteína garantiza más piezas de pan por bulto, excelente apariencia y sabor. Por su gran capacidad de absorción de agua, ofrece alta vida de anaquel en producto terminado y tolerancia a la fermentación. Se recomienda su uso con maquinaria industrial en procesos directos y de congelación. Para uso con maquinaria industrial en procesos directos y de congelación.", "usos": "Ideal para Pan Europeo, Pan Congelado, Pan Danés, Baguette, Pan de caja, entre otros.", "presentaciones": "44kg.", "comentarios": "", "url": "http://www.harinera-guadalupe.com/" }
];

const ho = [
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/elobo.jpg"), "marca": "EL LOBO", "tipo_harina": "HARINA FUERTE OBTENIDA DE LA MOLIENDA Y TAMIZADO DE LA MEZCLA DE GRANOS DE TRIGOS DUROS Y SUAVES DE LA MEJOR SELECCIÓN.", "usos": "PARA LA ELABORACIÓN DE TODO TIPO DE PAN INDUSTRIALIZADO, PAN FRANCÉS, CEMITA, BOLILLO, TELERA, ENTRE OTROS, GARANTIZANDO LA CANTIDAD ADECUADA DE PROTEÍNA Y SU CALIDAD.", "presentaciones": "44 kg/ 10 kg","comentarios":"CONTACTO: 222 429 2109", "url": "https://www.facebook.com/CHOSAPue/" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/laloba.jpg"), "marca": "LA LOBA", "tipo_harina": "HARINA SEMIFUERTE OBTENIDA DE LA MOLIENDA Y TAMIZADO DE LA MEZCLA DE GRANOS DE TRIGOS DUROS Y SUAVES DE LA MEJOR SELECCIÓN.", "usos": "PARA LA ELABORACIÓN DE TODO TIPO DE PAN, TORTILLAS, REPOSTERÍA (PASTELES, TARTAS), OBLEAS, GALLETAS, COBERTURA DE CACAHUATES TIPO JAPONESES, ENTRE OTROS", "presentaciones": "44 kg/ 10 kg", "comentarios": "CONTACTO: 222 429 2109", "url": "https://www.facebook.com/CHOSAPue/" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/virrey.jpg"), "marca": "VIRREY", "tipo_harina": "HARINA FUERTE OBTENIDA DE LA MOLIENDA Y TAMIZADO DE LA MEZCLA DE GRANOS DE TRIGOS DUROS Y SUAVES DE LA MEJOR SELECCIÓN", "usos": "PARA LA ELABORACIÓN DE TODO TIPO DE PAN INDUSTRIALIZADO, PAN FRANCÉS, CEMITA, BOLILLO, TELERA, ENTRE OTROS, GARANTIZANDO LA CANTIDAD ADECUADA DE PROTEÍNA Y SU CALIDAD.", "presentaciones": "44 kg", "comentarios": "CONTACTO: 222 429 2109", "urlhttps://www.facebook.com/CHOSAPue/": "" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/maya_.jpg"), "marca": "MAYA", "tipo_harina": "HARINA ESPECIALIZADA OBTENIDA DE LA MOLIENDA Y TAMIZADO DE LA MEZCLA DE GRANOS DE TRIGOS DUROS Y SUAVES DE LA MEJOR SELECCIÓN.", "usos": "PARA LA ELABORACIÓN DE TODO TIPO DE PAN, TORTILLAS, REPOSTERÍA (PASTELES, TARTAS), OBLEAS, GALLETAS, COBERTURA DE CACAHUATES TIPO JAPONESES, ENTRE OTROS", "presentaciones": "44 kg", "comentarios": "CONTACTO: 222 429 2109", "url": "https://www.facebook.com/CHOSAPue/" },

    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/lisy.jpg"), "marca": "Lisy", "tipo_harina": "HARINA PREPARADA PARA CREPAS", "usos": "HARINA PREPARADA PARA ELABORAR CREPAS, DE FÁCIL COMBINACIÓN PARA USO EN CREPAS DUCLES O SALADAS, PASTEL DE CREPAS, ENTRE OTROS.", "presentaciones": "1kg.", "comentarios": "", "url": "https://lisy.com.mx/" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/lisy.jpg"), "marca": "Lisy", "tipo_harina": "HARINA PREPARADA PARA PIZZA", "usos": "HARINA PREPARADA PARA ELABORAR PIZZA, PANIFICACIÓN ITALIANA COMO STROMBOLI, FOCACCIA, CALZONE, ENTRE OTROS.", "presentaciones": "1kg.", "comentarios": "", "url": "https://lisy.com.mx/" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/lisy.jpg"), "marca": "Lisy", "tipo_harina": "HARINA PREPARADA PARA WAFFLES", "usos": "HARINA PREPARADA PARA ELABORAR  WAFFLES, CAPEADOS (BANDERILLAS).", "presentaciones": "1kg.", "comentarios": "", "url": "https://lisy.com.mx/" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/lisy.jpg"), "marca": "Lisy", "tipo_harina": "HARINA PREPARADA PARA CHURROS", "usos": "HARINA PREPARADA PARA ELABORAR CHURRO TIPO ESPAÑOL Y CHURRO  RELLENO, ASÍ COMO CONOS DE CHURRO PARA HELADO, DONA DE CHURRO, ENTRE OTROS.", "presentaciones": "1kg.", "comentarios": "", "url": "https://lisy.com.mx/" },
    //{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/lisy.jpg"), "marca": "Lisy", "tipo_harina": "HARINA PREPARADA PARA BIZCOCHO", "usos": "HARINA PREPARADA PARA  ELABORAR PAN TIPO BIZCOCHO, CONCHAS, PAN DANÉS, LA TRADICIONAL ROSCA DE REYES Y PAN  DE MUERTO.C9:C10", "presentaciones": "1kg. 5kg.", "comentarios": "", "url": "https://lisy.com.mx/" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/lisy.jpg"), "marca": "Lisy", "tipo_harina": "HARINA PREPARADA PARA HOT CAKES (KOSHER)", "usos": "HARINA PREPARADA PARA ELABORAR HOT CAKES, PASTELES, CAPEADOS (BANDERILLAS), CUP CAKES, MUFFINS, ENTRE OTROS.", "presentaciones": "1kg.", "comentarios": "", "url": "https://lisy.com.mx/" },

    /*{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/ho_molinopuebla_ .png"), "marca": "VIRREY", "tipo_harina": "FUERTE", "usos": "SE EMPLEA EN LA ELABORACIÓN DE TODO TIPO DE PAN INDUSTRIALIZADO, PAN FRANCÉS, ENTRE OTROS. PARA LO CUAL SE REQUIERE UNA BUENA CANTIDAD Y CALIDAD DE PROTEÍNA.", "presentaciones": "44kg.", "comentarios": "HARINA FUERTE OBTENIDA DE LA MOLIENDA Y TAMIZADO DE LA MEZCLA DE GRANOS IMPORTADOS DE TRIGO ALTA PROTEÍNA, TRIGO DURO Y SUAVE DE LA MEJOR SELECCIÓN.", "url": "https://www.harineradeoriente.com/harina-de-trigo" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/ho_molinopuebla_ .png"), "marca": "EL LOBO", "tipo_harina": "FUERTE", "usos": "SE EMPLEA EN LA ELABORACIÓN DE TODO TIPO DE PAN INDUSTRIALIZADO, PAN FRANCÉS, ENTRE OTROS. PARA LO CUAL SE REQUIERE UNA BUENA CANTIDAD Y CALIDAD DE PROTEÍNA.", "presentaciones": "44kg.", "comentarios": "HARINA FUERTE OBTENIDA DE LA MOLIENDA Y TAMIZADO DE LA MEZCLA DE GRANOS IMPORTADOS DE TRIGO ALTA PROTEÍNA, TRIGO DURO Y SUAVE DE LA MEJOR SELECCIÓN.", "url": "https://www.harineradeoriente.com/harina-de-trigo" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/ho_molinopuebla_ .png"), "marca": "EL LOBITO", "tipo_harina": "FUERTE", "usos": "SE EMPLEA EN LA ELABORACIÓN DE TODO TIPO DE PAN INDUSTRIALIZADO, PAN FRANCÉS, ENTRE OTROS. PARA LO CUAL SE REQUIERE UNA BUENA CANTIDAD Y CALIDAD DE PROTEÍNA.", "presentaciones": "10kg.", "comentarios": "HARINA FUERTE OBTENIDA DE LA MOLIENDA Y TAMIZADO DE LA MEZCLA DE GRANOS IMPORTADOS DE TRIGO ALTA PROTEÍNA, TRIGO DURO Y SUAVE DE LA MEJOR SELECCIÓN.", "url": "https://www.harineradeoriente.com/harina-de-trigo" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/ho_molinopuebla_ .png"), "marca": "LA LOBA", "tipo_harina": "SEMIFUERTE", "usos": "SE EMPLEA EN LA ELABORACIÓN DE PAN ARTESANAL, TORTILLAS, REPOSTERÍA (PASTELES, TARTAS, GALLETAS, ENTRE OTROS).", "presentaciones": "44kg.", "comentarios": "HARINA FUERTE OBTENIDA DE LA MOLIENDA Y TAMIZADO DE LA MEZCLA DE GRANOS IMPORTADOS DE TRIGO ALTA PROTEÍNA, TRIGO DURO Y SUAVE DE LA MEJOR SELECCIÓN.", "url": "https://www.harineradeoriente.com/harina-de-trigo" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/ho_molinopuebla_ .png"), "marca": "LA LOBITA", "tipo_harina": "SEMIFUERTE", "usos": "SE EMPLEA EN LA ELABORACIÓN DE PAN ARTESANAL, TORTILLAS, REPOSTERÍA (PASTELES, TARTAS, GALLETAS, ENTRE OTROS).", "presentaciones": "10kg.", "comentarios": "HARINA FUERTE OBTENIDA DE LA MOLIENDA Y TAMIZADO DE LA MEZCLA DE GRANOS IMPORTADOS DE TRIGO ALTA PROTEÍNA, TRIGO DURO Y SUAVE DE LA MEJOR SELECCIÓN.", "url": "https://www.harineradeoriente.com/harina-de-trigo" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/ho_molinopuebla_ .png"), "marca": "ORIENTE - LA LOBA", "tipo_harina": "HARINA PREPARADA PARA PASTEL PREMIUM SABOR VAINILLA", "usos": "HARINA PREPARADA PARA ELABORAR PASTELES, MUFFINS, ROSCAS, PANQUÉS, ENTRE OTROS, SABOR VAINILLA.", "presentaciones": "1kg. y 5kg.", "comentarios": "UNA MEZCLA DE INGREDIENTES QUE TE AYUDARÁN A ELABORAR DE UNA MANERA RÁPIDA Y FÁCIL PASTEL DE VAINILLA, CUPCAKES, PANQUÉ Y MUCHO MÁS.", "url": "https://www.harineradeoriente.com/harinas-preparadas" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/oriente/ho_molinopuebla_ .png"), "marca": "ORIENTE - EL LOBO", "tipo_harina": "HARINA PREPARADA PARA PASTEL SABOR CHOCOLATE", "usos": "HARINA PREPARADA PARA ELABORAR PASTELES, MUFFINS, ROSCAS, PANQUÉS, ENTRE OTROS, SABOR CHOCOLATE.", "presentaciones": "1kg. y 5kg.", "comentarios": "UNA MEZCLA DE INGREDIENTES QUE TE AYUDARÁN A ELABORAR DE UNA MANERA RÁPIDA Y FÁCIL PASTEL DE CHOCOLATE, CUPCAKES, BROWNIES, PANQUÉ Y MUCHO MÁS.", "url": "https://www.harineradeoriente.com/harinas-preparadas" },*/
    
];

const tlanepantla = [
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/tlanepantla/harina_atica.png"), "marca": "Atica", "tipo_harina": "Extrafina", "usos": "Multiusos para panificación, pizzas, pasteles etc.", "presentaciones": "44kg 10kg", "comentarios": "", "url": "https://harinera.com.mx/"},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/tlanepantla/harina_aticaT.png"), "marca": "Atica T", "tipo_harina": "Extrafina", "usos": "Tortilla, panes planos, crepas, pan árabe, buñuelos.", "presentaciones": "44kg.", "comentarios": "", "url": "https://harinera.com.mx/"},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/tlanepantla/harina_alpesa.png"), "marca": "Alpesa", "tipo_harina": "Suave", "usos": "Galletera, obleas, conos, churros, confitado de cacahuate, pastel.", "presentaciones": "44kg.", "comentarios": "", "url": "https://harinera.com.mx/"},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/tlanepantla/harina_alpesaespecial.png"), "marca": "Alpesa Especial", "tipo_harina": "Fina", "usos": "Pan Francés, Bizcocho, danés, feite, pasteles, etc.", "presentaciones": "44kg.", "comentarios": "", "url": "https://harinera.com.mx/"},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/tlanepantla/harina_integral.png"), "marca": "Integral", "tipo_harina": "Semifina", "usos": "Cualquier tipo de pan Integral.", "presentaciones": "44kg.", "comentarios": "", "url": "https://harinera.com.mx/"},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/tlanepantla/harina_estandar.png"), "marca": "Estandar", "tipo_harina": "Semifina", "usos": "Pan Regional.", "presentaciones": "44kg.", "comentarios": "", "url": "https://harinera.com.mx/"}
];

const moderna = [
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/moderna_laperla.png"), "marca": "La Perla", "tipo_harina":"Fuerza alta", "usos":"Multipropósito", "presentaciones": "44kg 25kg 10kg 9kg 5kg 4lbs 2lbs 1kg 800gr", "comentarios":"", "url":"www.lamoderna.com.mx"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/moderna_laperla.png"), "marca": "La Perla Fuerte", "tipo_harina":"Alta proteina", "usos":"Panaderia mecanizada e industrial", "presentaciones": "44kg 25kg", "comentarios":"", "url":"www.lamoderna.com.mx"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/moderna_laperla.png"), "marca": "La Perla Tortilla", "tipo_harina":"Suave", "usos":"Tortilleria artesanal y mecanizada", "presentaciones": "44kg 25kg 4lbs 907gr", "comentarios":"", "url":"www.lamoderna.com.mx"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/moderna_laperlaintegrl.png"), "marca": "La Perla Integral", "tipo_harina":"Integral", "usos":"Multipropósito", "presentaciones": "44kg. 22kg. 2kg. 907gr.", "comentarios":"", "url":"www.lamoderna.com.mx"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/moderna_lirio.png"), "marca": "Lirio", "tipo_harina":"Fuerza media", "usos":"Multipropósito", "presentaciones": "44kg. 1kg. 907gr.", "comentarios":"", "url":"www.lamoderna.com.mx"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/moderna_pavo.png"), "marca": "Pavo", "tipo_harina":"Fuerza media", "usos":"Multipropósito", "presentaciones": "44kg. 907gr.", "comentarios":"", "url":"www.lamoderna.com.mx"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/moderna_rendidora.png"), "marca": "Rendidora", "tipo_harina":"Fuerza media", "usos":"Panadería mecanizada", "presentaciones": "44kg. 1kg.", "comentarios":"", "url":"www.lamoderna.com.mx"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/moderna_angelito.png"), "marca": "Angelito", "tipo_harina":"Suave", "usos":"Panadería y tortillería artesanal", "presentaciones": "44kg.", "comentarios":"", "url":"www.lamoderna.com.mx"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/moderna_cisnedeoro.png"), "marca": "Cisne de Oro", "tipo_harina":"Pastelera", "usos":" Pastelería", "presentaciones": "44kg. 1kg.", "comentarios":"", "url":"www.lamoderna.com.mx"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/100_lamoderna.jpg"), "marca": "La Moderna", "tipo_harina":"Fuerza media", "usos":"Multiproposito", "presentaciones": "1kg  ", "comentarios":"", "url":"www.lamoderna.com.mx"},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/logos-lamoderna.png"), "marca": "San Antonio", "tipo_harina":"Fuerza media", "usos":"Multiproposito", "presentaciones": "4lbs 2lbs ", "comentarios":"", "url":"www.lamoderna.com.mx"},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/100_lamoderna.jpg"), "marca": "La Moderna", "tipo_harina":"Semola de trigo durum", "usos":"Pastas, pizzas, cereales", "presentaciones": "1kg", "comentarios":"", "url":"www.lamoderna.com.mx"},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/moderna_laperla.png"), "marca": "La Perla", "tipo_harina":"Semola de trigo durum", "usos":"Pastas, pizzas, cereales", "presentaciones": "10kg", "comentarios":"", "url":"www.lamoderna.com.mx"},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/logos-lamoderna.png"), "marca": "Tres Estrellas", "tipo_harina":"Harina de trigo preparada para Pizza", "usos":"Pizzas", "presentaciones": "1kg", "comentarios":"", "url":"www.lamoderna.com.mx"},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/logos-lamoderna.png"), "marca": "Tres Estrellas", "tipo_harina":"Harina de trigo preparada para Hot Cakes", "usos":"Hot cakes", "presentaciones": "1kg 850gr 800gr 500gr", "comentarios":"", "url":"www.lamoderna.com.mx"},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/moderna_laperla.png"), "marca": "La Perla", "tipo_harina":"Harina de trigo preparada para Hot Cakes", "usos":"Hot cakes", "presentaciones": "800gr", "comentarios":"", "url":"www.lamoderna.com.mx"},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/la_moderna/moderna_laperla.png"), "marca": "La Perla", "tipo_harina":"Harina de trigo preparada para Tortillas", "usos":"Tortilla casera", "presentaciones": "900gr", "comentarios":"", "url":"www.lamoderna.com.mx"},


];

const trimex = [
    //{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_bebeagua.png"), "marca": "BEBE AGUA", "tipo_harina": "Panificable", "usos": "Fabricación mecanizada de pan blanco, pan artesanal y pan dulce.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_diez lustros.png"), "marca": "DIEZ LUSTROS", "tipo_harina": "Panificable", "usos": "Pan blanco, pan artesanal, pan dulce y galletas tipo pasta seca.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	//{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_euskaro.png"), "marca": "EUSKARO", "tipo_harina": "Panificable", "usos": "Pan blanco, pan artesanal, pan dulce y galletas tipo pasta seca.", "presentaciones": "SACOS: 10kg. 44kg.", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_flores.png"), "marca": "FLORES", "tipo_harina": "Panificable y para tortillas", "usos": "Consumo general para la elaboración de tortillas y pan.", "presentaciones": "SACO(44kg)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_fresa.png"), "marca": "FRESA", "tipo_harina": "Galletera, panificable y para tortillas", "usos": "Galletas de fermentación, galletas de leudado, pan blanco, pan dulce, tortillas y pasta.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_grano de plata.png"), "marca": "GRANO DE PLATA", "tipo_harina": "Galletera y panificable", "usos": "Pan blanco, pan artesanal, pan dulce y galletas tipo pasta seca.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_guadalupana.png"), "marca": "GUADALUPANA", "tipo_harina": "Galletera y panificable", "usos": "Pan blanco, pan artesanal, pan dulce, tortillas y galletas.", "presentaciones": "SACO(44kg).", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_hojadeoro.png"), "marca": "HOJA DE ORO", "tipo_harina": "Panificable y para tortillas", "usos": "Fabricación mecanizada de pan blanco, pan artesanal, pan dulce y tortillas.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_lidia.png"), "marca": "LIDIA", "tipo_harina": "Galletera, productos fritos y panificable", "usos": "Pan blanco, pan artesanal, pan dulce, galletas, frituras, churros y barquillos.", "presentaciones": "SACOs: 5kg. 10kg. 44kg.", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_Marfil.png"), "marca": "MARFIL", "tipo_harina": "Galletera, productos fritos y panificación", "usos": "Pan blanco, pan artesanal, pan dulce, galletas, frituras, churros y barquillos.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_Medalladeoro panificable.png"), "marca": "MEDALLA DE ORO", "tipo_harina": "Panificable", "usos": "Fabricación mecanizada de pan blanco, pan artesanal y pan dulce.", "presentaciones": "SACOS: 10kg. 22kg. 44kg.", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_medalladeoro_c1frances.png"), "marca": "MEDALLA DE ORO C1 FRANCÉS", "tipo_harina": "Panificable", "usos": "Consumo general para pan, contiene mejorante para panificación.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_Medalladeoro panificable.png"), "marca": "MEDALLA DE ORO PANIFICABLE", "tipo_harina": "Panificable y para tortillas", "usos": "Consumo general para la elaboración de tortillas y pan.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_Medalla de plata.png"), "marca": "MEDALLA DE PLATA", "tipo_harina": "Panificable y para tortillas", "usos": "Pan blanco, pan artesanal, pan dulce y tortillas.", "presentaciones": "SACOS: 10kg. 44kg.", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_sacoF.png"), "marca": "SACO F", "tipo_harina": "Galletera y para productos fritos", "usos": "Galletas, frituras, churros, barquillos y confinados de cacahuate.", "presentaciones": "SACOS: 10kg. 44kg.", "comentarios": "", "url": "http://gtrimex.mx/" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/Logo_Trimexnuevo.png"), "marca": "SACO F SUAVE", "tipo_harina": "Galletera, productos fritos y panificación", "usos": "Galletas, frituras, churros, barquillos y pan dulce.", "presentaciones": "SACOS: SACO 44KG", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_san marcos asmsa.png"), "marca": "SAN MARCOS", "tipo_harina": "Panificable y para tortillas", "usos": "Pan blanco, pan artesanal, pan dulce y tortillas.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_saratoga.png"), "marca": "SARATOGA", "tipo_harina": "Pastelera y productos fritos", "usos": "Pasteles, base para harinas preparadas, obleas y frituras de extrusión.", "presentaciones": "SACOS: 25kg. 44kg.", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_seisaspas.png"), "marca": "SEIS ASPAS", "tipo_harina": "Panificable", "usos": "Fabricación mecanizada de pan blanco, pan artesanal y pan dulce.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/Logo_Trimexnuevo.png"), "marca": "SILAO", "tipo_harina": "Panificable y galletera", "usos": "Pan blanco, pan artesanal semi mecanizado, pan dulce y galletas.", "presentaciones": "SACO 44KG", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_Suprema S.png"), "marca": "SUPREMA S", "tipo_harina": "Panificable y para tortillas", "usos": "Consumo general para la elaboración de tortillas y pan.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_supremacompleta.png"), "marca": "SUPREMA COMPLETA", "tipo_harina": "Panificable", "usos": "Consumo general para pan, contiene mejorante para panificación.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_suprema50.png"), "marca": "SUPREMA 50", "tipo_harina": "Panificable y pizza", "usos": "Pan de caja, pan blanco tipo francés, pan dulce y base para pizza.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	//{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_silao.png"), "marca": "SILAO", "tipo_harina": "Panificable y galletera", "usos": "Pan blanco, pan artesanal semi mecanizado, pan dulce y galletas.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_tacurina.png"), "marca": "TACUARINA", "tipo_harina": "Galletera y para productos fritos", "usos": "Galletas de fermentación, galletas de leudado, barquillo, cobertura de cacahuate y frituras de extrusión.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
	{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_victoria.png"), "marca": "VICTORIA", "tipo_harina": "Panificable y pizza", "usos": "Pan de caja, pan blanco tipo francés, pan dulce y base para pizza.", "presentaciones": "SACO(44kg.)", "comentarios": "", "url": "http://gtrimex.mx/" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/trimex_xaley.png"), "marca": "XALEY", "tipo_harina": "Panificable y pizza", "usos": "Pan de caja, pan blanco tipo francés, pan dulce y base para pizza.", "presentaciones": "SACOS: 25kg. 30kg. 44kg.", "comentarios": "", "url": "http://gtrimex.mx/" },
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex/Logo_Trimexnuevo.png"), "marca": "YOREDA", "tipo_harina": "Galletera, para tortillas y pasta", "usos": "Galletas de fermentación, galletas de leudado, tortillas y pasta.", "presentaciones": "SACO 44KG", "comentarios": "", "url": "http://gtrimex.mx/" }
];

const trimex_molinera = [
    //{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex_molinera/molinera_selecta.png"), "marca":"YOREDA", "tipo_harina": "Galletera, para tortillas y pasta", "usos": "Galletas de fermentación, galletas de leudado, tortillas y pasta.", "presentaciones": "SACO(44kg.)", "comentarios":"", "url": "http://gtrimex.mx/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex_molinera/molinera_selecta.png"), "marca":"SELECTA", "tipo_harina": "HARINA BLANCA, HARINA EXTRA BLANCA, HARINA INTEGRAL", "usos": "HARINA MULTIUSO, IDEAL PARA PAN, TORTILLA, RESPOSTERÍA, CAPEADOS, GALLETA, ETC.", "presentaciones": "PAQUETE(1kg.)", "comentarios":"", "url": "http://www.molineramx.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex_molinera/molinera_selectaTortilla.png"), "marca":"SELECTA MIX TORTILLA", "tipo_harina": "HARINA PREPARADA PARA TORTILLA", "usos": "HARINA ESPECIAL PARA HACER TORTILLA", "presentaciones": "PAQUETEs: 500g. 1kg.", "comentarios":"", "url": "http://www.molineramx.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex_molinera/molinera_selecta pastel.png"), "marca":"SELECTA PASTEL", "tipo_harina": "HARINA PREPARADA PARA PASTEL", "usos": "HARINA ESPECIAL PARA HACER PASTEL Y CUPCAKES SABOR VAINILLA O CHOCOLATE", "presentaciones": "PAQUETE(500g.)", "comentarios":"", "url": "http://www.molineramx.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex_molinera/molinera_selectaTortilla.png"), "marca":"SELECTA TORTILLA", "tipo_harina": "HARINA PARA TORTILLA ALTA PROTEÍNA, TORTILLA FUERTE, TORTILLA SUAVE-ARTESANAL Y TORTILLA EXTRA BLANCA", "usos": "TORTILLA PARA PROCESO MECANIZADOS, SEMI-MERCANIZADOS Y MANUAL", "presentaciones": "SACO(44kg.)", "comentarios":"", "url": "http://www.molineramx.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex_molinera/molinera_selectaPan.png"), "marca":"SELECTA PAN", "tipo_harina": "HARINA PARA PAN ALTA PROTEÍNA, PAN FUERTE, PAN MEDIA-TRADIONAL Y PAN SUAVE-ARTESANAL", "usos": "PAN BLANCO, PAN FRANCÉS, PAN PARA HOTDOG, BOLLO DE HAMBURGUESA, BIZCOCHO, FEITÉ, GALLETAS, PASTELILLOS, ENTRE OTROS.", "presentaciones": "SACO(44kg.)", "comentarios":"", "url": "http://www.molineramx.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex_molinera/molinera_chamizal.png"), "marca":"CHAMIZAL", "tipo_harina": "HARINA BLANCA", "usos": "HARINA MULTIUSO, IDEAL PARA PAN, TORTILLA, RESPOSTERÍA, CAPEADOS, GALLETA, ETC.", "presentaciones": "PAQUETE(1kg.)", "comentarios":"", "url": "http://www.molineramx.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex_molinera/molinera_eldiluvio.png"), "marca":"EL DILUVIO", "tipo_harina": "HARINA BLANCA", "usos": "HARINA MULTIUSO, IDEAL PARA PAN, TORTILLA, RESPOSTERÍA, CAPEADOS, GALLETA, ETC.", "presentaciones": "PAQUETE(1kg.)", "comentarios":"", "url": "http://www.molineramx.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex_molinera/molinera_mayran.png"), "marca":"MAYRAN", "tipo_harina": "HARINA BLANCA", "usos": "HARINA MULTIUSO, IDEAL PARA PAN, TORTILLA, RESPOSTERÍA, CAPEADOS, GALLETA, ETC.", "presentaciones": "SACO(44kg.)", "comentarios":"", "url": "http://www.molineramx.com/" },

{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex_molinera/molinera_selecta.png"), "marca":"SELECTA", "tipo_harina": "HARINA BLANCA", "usos": "HARINA PARA PAN BLANCO, PAN FRANCÉS, PAN PARA HOT DOG, HAMBURGUESA, DANÉS, CHURRO, BIZCOCHO Y REPOSTERÍA", "presentaciones": "SACO DE 5KG Y 10 KG", "comentarios":"", "url": "http://www.molineramx.com/" },
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/trimex_molinera/molinera_selecta.png"), "marca":"SELECTA HARINA PREPARADA", "tipo_harina": "HARINA PREPARADA PARA PANADERÍA, BOLLO DE PAPA, TORTILLERÍA, PIZZA, PASTELERÍA, BIZCOCHO, DONA, DANES, DON Y TEMPORADA (PAN DE MUERTO Y ROSA DE REYES)", "usos": "USANDO NUESTRA HARINA PREPARADA SE LOGRARÁ UNA MAYOR EFICIENCIA  PERATIVA, CALIDAD UNIFORME Y PREPARAR LOS PRODUCTOS DE MANERA FÁCIL Y RÁPIDA.", "presentaciones": "SACO DE 20KG", "comentarios":"", "url": "http://www.molineramx.com/" },
];

const trimex_espiga = [
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Espiga Fina", "tipo_harina": "Harina de trigo blanca", "usos": "• Alto contenido de proteína • Ideal para la panificación de pan blanco, salado, dulce, panquelería y repostería • Alta capacidad de absorción • Gran rendimiento: más piezas de pan por saco • Gran volumen y excelente fuerza • Miga fina y uniforme • Calidad superior y constante</>", "presentaciones": "Saco 44 kg y Saco de 10 kg", "comentarios":"Es la harina más versátil del portafolio de Espiga®.", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Espiga Especial", "tipo_harina": "Harina de trigo blanca", "usos": "• Harina de trigo fuerte • Alto contenido de proteína • Ideal para la panificación de birote, pan blanco y bolillo • Alta capacidad de absorción • Gran rendimiento: más piezas de pan por saco • Gran volumen y fuerza • Excelente blancura • Mayor tiempo de vida en anaquel", "presentaciones": "Saco 44 kg", "comentarios":"Es la harina ideal para obtener los mejores y más crujientes birotes para las tradicionales tortas ahogadas.", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Espiga Esponja", "tipo_harina": "Harina de trigo blanca", "usos": "• Harina de trigo fuerte, ideal para elaborar pan blanco, pan dulce, pizza, pan artesanal, pan europeo, chapatas, baguettes y más • Alta capacidad de absorción • Gran volumen y excelente fuerza • Miga más fina • Gran rendimiento: más piezas de pan por saco", "presentaciones": "Saco 44 kg", "comentarios":"Harina que se caracteriza principalmente por su bajo índice de cenizas y su color más blanco, lo que genera una miga más fina.", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Espiga Integral", "tipo_harina": "Harina de trigo blanca", "usos": "• Alto contenido de proteína • Excelente fuente de fibra y salvado • Ideal para la elaboración de bolillo, bizcocho, danés, feité, pan de caja, bísquet, baguette, muffin, galletas y hasta tortillas integrales • Alta capacidad de absorción • Gran rendimiento: más piezas de pan por saco", "presentaciones": "Saco 44 kg", "comentarios":"Es una harina versátil e ideal para cualquier tipo de procesos en panificación integral", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Espiga Tortilla", "tipo_harina": "Harina de trigo blanca", "usos": "• Tortillas más frescas, simétricas y con amplia vida de anaquel • Excelente blancura, aroma y sabor • Genera masas más manejables y moldeables • Alta absorción y fácil manejo • Adaptable para cualquier tipo de proceso • Gran rendimiento: más piezas de tortilla por saco", "presentaciones": "Saco 44 kg", "comentarios":"Es la harina de alto rendimiento ideal para elaborar tortillas perfectas con mayor suavidad y blancura.", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Espiga Tortilla Max", "tipo_harina": "Harina de trigo blanca", "usos": "• Ideal para la elaboración de tortillas de harina frescas y simétricas • Máxima suavidad y exquisito sabor • Muy manejable para ser trabajada • Tortillas con vida de anaquel más larga • Gran rendimiento: más piezas de tortilla por saco", "presentaciones": "Saco 44 kg", "comentarios":"Harina de trigo desarrollada para tortillerías con los estándares más exigentes de calidad; se distingue por ser muy fácil de manejar, es estable y uniforme.", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Espiga Pastelera", "tipo_harina": "Harina de trigo blanca", "usos": "• Harina de trigo suave, ideal para la creación de pasteles, panqués, waffles, hot cakes, roscas, muffins, brownies y más • Excelente textura y volumen en los pasteles • Genera un poro más fino y mayor frescura para el producto final • Gran rendimiento: más piezas de producto por saco • Excelente absorción y retención de agua • Calidad superior, constante y uniforme", "presentaciones": "Saco 44 kg", "comentarios":"Te brinda la practicidad de utilizar un solo tipo de harina de trigo para elaborar múltiples productos de repostería.", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Espiga Galletera", "tipo_harina": "Harina de trigo blanca", "usos": "• Harina de trigo suave, ideal para elaborar todo tipo de galletas, polvorones, pays, obleas, barquillos, conos de helado, botanas, etc. • Buen rendimiento: más galletas por saco • Brinda el tamaño y espesor deseado en las galletas • Calidad superior, constante y uniforme", "presentaciones": "Saco 44 kg", "comentarios":"Ideal para elaborar todo tipo de galletas y otros productos similares. ", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Villa Rica", "tipo_harina": "Harina de trigo blanca", "usos": "• Harina de trigo suave de fácil manejo, ideal para crear pan salado y dulce,  bizcocho, hojaldre, donas de levadura, galletas saladas, pizza y más • Menor tiempo de fermentación • Calidad uniforme a un súper precio • Pan suave y esponjoso • Gran rendimiento: más piezas de pan por saco", "presentaciones": "Saco 44 kg y Saco de 10 kg", "comentarios":"Es una harina de trigo suave ideal para elaborar todo tipo de pan.", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Cuautémoc", "tipo_harina": "Harina de trigo blanca", "usos": "• Harina de trigo suave de fácil manejo, ideal para crear pan salado y dulce, bizcocho, hojaldre, donas de levadura, galletas saladas, pizza y más • Menor tiempo de fermentación • Calidad uniforme a un súper precio • Pan suave y esponjoso • Gran rendimiento: más piezas de pan por saco • Suavidad y textura incomparables", "presentaciones": "Saco 44 kg", "comentarios":"Harina de trigo recomendada para procesos rústicos y artesanales.", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Escudo Plus", "tipo_harina": "Harina de trigo blanca", "usos": "• Ideal para la elaboración de pan de caja, pan francés, pizza, panquelería, bollería, pan dulce, hojaldre, tortillas de harina y más • Alta capacidad de absorción y retención de agua • Buen nivel de blancura • Masas muy manejables • Gran rendimiento: más piezas por saco", "presentaciones": "Saco 44 kg y Saco de 25 kg", "comentarios":"Es una harina versátil que proporciona el balance perfecto entre volumen y fuerza.", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"San Vicente", "tipo_harina": "Harina de trigo blanca", "usos": "• Harina de trigo de fuerza media, ideal para elaborar pan salado, pan dulce, pan rústico, hojaldre, pizzas, tortillas de harina y más • Brinda buen nivel de blancura • Ideal para acabados más finos • Fácil manejo • Excelente absorción y retención de agua • Gran rendimiento: más piezas por saco", "presentaciones": "Saco 44 kg", "comentarios":"Harina de trigo versátil, ideal para producción de altos volúmenes de pan en procesos tradicionales, artesanales y semi industriales.", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Manitoba", "tipo_harina": "Harina de trigo blanca", "usos": "• Alto contenido de proteína • Ideal para preparar pan de caja, pan francés, bollería, pan dulce, tortillas, pizzas, dona de levadura, entre otros • Alta capacidad de absorción • Gran rendimiento: más piezas de pan por saco • Gran volumen y excelente fuerza • Fácil manejo", "presentaciones": "Saco 44 kg y Saco de 25 kg", "comentarios":"Harina de trigo ideal para la elaboración de todo tipo de pan y es especialmente recomendada para producir altos volúmenes de piezas.", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Richcreme Vainilla", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para elaborar todo tipo de panquelería: muffins, roscas, panqués, mantecadas, pasteles y mucho más.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Richcreme Chocolate", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para elaborar todo tipo de panquelería sabor chocolate: muffins, roscas, panqués, mantecadas, pasteles y mucho más.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Pudding Cake", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para crear deliciosa panquelería fina como cupcakes, mantecadas y mucho más.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Esponja Sabor Vainilla", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para elaborar pasteles con alta retención de líquidos, permitiendo mantener la estructura a pesar de ser humedecida con licores, jarabes o leches. Crea deliciosos pasteles de tres leches, pastel envinado, brazo gitano y mucho más.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Esponja Sabor Mokaccino", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para elaborar pasteles sabor mokaccino de excelente consistencia, sabor, color y alta retención de líquidos.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Premium Sabor Vainilla", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada con la que puedes crear distintos tipos de pasteles tipo americano, imposible, con fondant o con cobertura cremosa y mucho más.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Premium Sabor Chocolate", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada con la que podrás elaborar pasteles tipo americano sabor chocolate. Por su consistencia y sabor es ideal para preparar pasteles con fondant o cobertura cremosa y mucho más.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Piña Colada", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada sabor piña colada con la que puedes elaborar deliciosos pasteles y panqués, así como volteados de piña, garibaldis, roscas, mantecadas, cupcakes y mucho más.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Red Velvet", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para elaborar fácilmente pasteles, panqués, cupcakes, cakepops y mucho más.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Superblend®", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para elaborar la tradicional dona de levadura así como una gran variedad de productos freídos como: donas tradicionales de levadura, berlinesas, garras, roles de manzana o canela, colchones y mucho más.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Dona Cake", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada con la que podrás crear deliciosas donas pastel (donas instantáneas) con una gran consistencia, sabor y apariencia.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Bizcocho", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para elaborar delicioso pan tradicional mexicano tipo bizcocho: conchas, chilindrinas, rebanadas, nubes y mucho más.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Danés", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para crear una gran variedad de delicioso pan tipo danés: cuernitos, bigotes, roles, trenzas, croissants y mucho más.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Churro", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para elaborar fácil y rápido los tradicionales churros mexicanos. Es fácil de preparar ya que sólo se necesita agregar agua y ¡está listo!", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Pan de Muerto", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para elaborar el tradicional pan de muerto fácil y rápido, con gran frescura, consistencia y suavidad que perduran por días.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Rosca de Reyes", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada que te brinda la facilidad de crear deliciosas y tradicionales Roscas de Reyes con una gran suavidad, olor, consistencia y sabor inigualable.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Pizza", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada con la que crearás ricas y suaves bases para pizza con mayor frescura, suavidad y rendimiento.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Bollo Núcleo", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para elaborar panes de hamburguesa, hot dogs y más. Te brinda mayor rendimiento de piezas al mezclarse con harina blanca.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Bísquet", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada para elaborar los deliciosos y tradicionales bísquets con intenso sabor a mantequilla.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mix Cobertura de Cacahuate", "tipo_harina": "Harina de trigo preparada", "usos": "Harina de trigo preparada ideal para obtener una cobertura crujiente y de buen sabor en los cacahuates.", "presentaciones": "Saco de 20 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Acemite de Trigo", "tipo_harina": "Derivados de trigo", "usos": "Producto con alto contenido de fibra en forma granular fina donde prevalecen los colores café claro, amarillo y blanco cremoso, obtenido a partir de trigos –suaves o fuertes- enteros, limpios y sanos de origen Mexicano o importado, por medio de procesos de trituración, compresión y separación en los que se separan los diferentes componentes por tamaño de partícula para conseguir el Acemite de trigo. Contiene harina de trigo, salvado y germen propios del proceso.", "presentaciones": "Saco 44 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Germen de Trigo", "tipo_harina": "Derivados de trigo", "usos": "Producto en forma de hojuelas de color amarillo y café, obtenido a partir de trigos enteros, -suaves y fuertes- limpios y sanos de variedades de trigo de origen Mexicano o importado, por medio de procesos de trituración, compresión y separación, en los que se logra obtener casi en su totalidad el Germen de trigo, seleccionado por el tamaño de partículas, y que es sometido a los tratamientos necesarios para cumplir con las legislaciones para consumo humano, contiene partículas de harina de trigo propias del mismo proceso. Es un alimento muy rico y beneficioso para el organismo.", "presentaciones": "Saco 30 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Granillo de Trigo", "tipo_harina": "Derivados de trigo", "usos": "Producto con alto contenido de fibra en forma granular de color café rojizo, amarillo y blanco cremoso, obtenido a partir de trigos enteros –suaves o fuertes-, limpios y sanos de origen Mexicano o importado, por medio de procesos de trituración, compresión y separación en los que se dividen los diferentes componentes por tamaño de partícula para conseguir el Granillo de trigo. Contiene harinas de trigo, salvado y germen de trigo propios del proceso.", "presentaciones": "Saco 30 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Mezcla Salvadillo", "tipo_harina": "Derivados de trigo", "usos": "Producto con alto contenido de fibra en forma de hojuelas de color café rojizo, obtenido a partir de trigos enteros –suaves o fuertes-, limpios y sanos. Puede ser de origen Mexicano o importado. Para el consumo humano, se obtiene por medio de procesos de trituración, compresión y separación. Puede ser usado también para consumo animal. Es un alimento rico en nutrientes y minerales esenciales para la salud", "presentaciones": "Saco 30 kg", "comentarios":"", "url": "https://espigamexico.com/"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/espiga/ESPIGA.png"), "marca":"Salvado de Trigo", "tipo_harina": "Derivados de trigo", "usos": "Producto con alto contenido de fibra en forma de hojuelas de color café rojizo, obtenido a partir de trigos enteros –suaves o fuertes-, limpios y sanos. Puede ser de origen Mexicano o importado. Para el consumo humano, se obtiene por medio de procesos de trituración, compresión y separación. Puede ser usado también para consumo animal. Es un alimento rico en nutrientes y minerales esenciales para la salud.", "presentaciones": "Saco 30 kg", "comentarios":"", "url": "https://espigamexico.com/"},
];
const real_altena = [
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/REAL_ALTENA_DERIVADOS_DE_TRIGO/porductos_altena__altaproteina.jpg"), "marca":"ALTA PROTEÍNA", "tipo_harina": "HARINA FUERTE DE ALTO RENDIMIENTO", "usos": "FABRICACIÓN MECANIZADA DE PAN BLANCO, PAN FRANCÉS, BIZCOCHO, PAN DE CAJA, BAGUETTE", "presentaciones": "44 kgs.", "comentarios":"MAYOR ABSORCIÓN, MAS VOLUMEN", "url": "realaltena.com"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/REAL_ALTENA_DERIVADOS_DE_TRIGO/porductos_altena_pan.jpg"), "marca":"PAN", "tipo_harina": "FUERTE", "usos": "PAN FRANCÉS, BOLLERÍA, PAN BLANCO, BIROTE, BOLILLO SALADO.", "presentaciones": "44 kgs.", "comentarios":"-", "url": "realaltena.com"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/REAL_ALTENA_DERIVADOS_DE_TRIGO/porductos_altena__artesanal_bolsa.jpg"), "marca":"ARTESANAL", "tipo_harina": "FUERZA MEDIA", "usos": "PANADERÍA, PAN FRANCÉS, BOLLERÍA, FEITÉ U HOJALDRE, MASA PARA PIZZA, PASTELES", "presentaciones": "44 kgs.", "comentarios":"-", "url": "realaltena.com"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/REAL_ALTENA_DERIVADOS_DE_TRIGO/porductos_altena__extrafina.jpg"), "marca":"EXTRAFINA", "tipo_harina": "FUERZA INTERMEDIA MULTIPROPÓSITO", "usos": "USO GENERAL PARA PRODUCTOS DE  PANIFICACIÓN", "presentaciones": "44 kgs. 10 kgs.", "comentarios":"-", "url": "realaltena.com"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/REAL_ALTENA_DERIVADOS_DE_TRIGO/porductos_altena__galletera.jpg"), "marca":"GALLETERA", "tipo_harina": "SUAVE", "usos": "ELABORACIÓN DE GALLETA MECANIZADA Y MANUAL, BOTANAS, POLVORONES, ENTRE OTROS", "presentaciones": "44 kgs.", "comentarios":"-", "url": "realaltena.com"},
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/REAL_ALTENA_DERIVADOS_DE_TRIGO/porductos_altena_tortilla.jpg"), "marca":"TORTILLA", "tipo_harina": "ESPECIAL ALTO RENDIMIENTO", "usos": "ELABORACIÓN DE TORTILLA", "presentaciones": "44 kgs. 1 kg.", "comentarios":"ESPECIAL PARA TORTILLA DE USO ARTESANAL E INDUSTRIAL, ALTA ESTABILIDAD Y BLANCURA EN EL PRODUCTO TERMINADO.", "url": "realaltena.com"},
];
const parayas = [
    { "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/parayas.png"), "marca":"Inmaculda", "tipo_harina": "Panadera, Extra Fina, Harina de Fuerza Media", "usos": "Pan blanco, Pan Dulce, Bolillos, Churros y Pizzas", "presentaciones": "Plastico: 1kg Rafia: 10kg 22kg 44kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Plastico: 1 kg Rafia: 10 kg y 25 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/espumadechapala.png"), "marca":"Espuma de Chapala", "tipo_harina": "Alta Patente", "usos": "Reposteria Fina, Pan blanco, pan dulce, Bolillos, Churros y Pizzas", "presentaciones": "Papel: 500gr 1Kg 2kg Plastico: 1kg 2kg Rafia: 10kg 24kg 44kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Papel: 500 gr, 1 kg y 2 kg Plastico: 1 kg y 2 kg Rafia: 10 kg y 25 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/parayas.png"), "marca":"Parayas", "tipo_harina": "Panadera Artesanal, Harina de Fuerza Media", "usos": "Galletas, Botanas, Polvorones", "presentaciones": "Rafia: 10kg 44kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Rafia: 10 kg y 25 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/logo_donmanuel.png"), "marca":"Don Manuel", "tipo_harina": "Panadera, Extra Fina, Harina Fuerte", "usos": "Pan blanco, pan dulce, Bolillos, Churros y Pizzas", "presentaciones": "Rafia: 44kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Rafia: 25 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/logo_donmanuel.png"), "marca":"Don Manuel Tortilla", "tipo_harina": "Panadera, Extra Fina", "usos": "Tortiilla, Pan Arabe", "presentaciones": "Rafia: 44kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Rafia: 25 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/inmaculada.png"), "marca":"Inmaculada Suave", "tipo_harina": "Harina Suave", "usos": "Frituras (Botanas), Galletas, Polvorones, Confitería y Pastelillos.", "presentaciones": "Rafia: 44kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Rafia: 25 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/espumadechapala.png"), "marca":"Espuma de Chapala Integral", "tipo_harina": "Panaderia Saludable", "usos": "Panaderia Saludable", "presentaciones": "Papel: 1kg Rafia: 10kg 44kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Papel: 1 kg Rafia: 25 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/parayas.png"), "marca":"Harina Estandar", "tipo_harina": "Harina Estandar", "usos": "Frituras (Cubierta de cacahuates), Comida para Peces y Aglomerados en Carpintería", "presentaciones": "Rafia: 10kg 44kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Rafia: 25 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/parayas.png"), "marca":"Parayas Cacahuate", "tipo_harina": "Harina de Fuerza Media", "usos": "Frituras (Cubierta de cacahuates", "presentaciones": "Rafia: 44kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Rafia: 25 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/espumadechapala.png"), "marca":"Hot Cakes Espuma de Chapala Tradicional", "tipo_harina": "Mixes", "usos": "Elaboracion de Hot Cakes  Tradicional", "presentaciones": "Trilaminado: 950gr", "comentarios":"Mixes Hot Cakes Tradicional", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/espumadechapala.png"), "marca":"Hot Cakes Espuma de Chapala Integral AL (Agregue Leche)", "tipo_harina": "Mixes Integral", "usos": "Recomendado para elaboración de Hot Cakes Integral", "presentaciones": "Trilaminado: 950gr", "comentarios":"Mixes Hot Cakes Integral", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/espumadechapala.png"), "marca":"Hot Cakes Espuma de Chapala AL (Agregue Leche)", "tipo_harina": "Mixes AL (Solo Agregue Leche)", "usos": "Recomendado para elaboración de Hot Cakes.", "presentaciones": "Trilaminado: 1Kg", "comentarios":"Mixes Hot Cakes Tradicional", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/parayas.png"), "marca":"Granillo", "tipo_harina": "Subproducto", "usos": "Subproducto del Trigo usado comúnmente en el proceso de la elaboración del pan para polvorear la masa y facilitar su manejo.", "presentaciones": "Rafia: 30kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Rafia: 25 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/parayas.png"), "marca":"Moyuelo", "tipo_harina": "Subproducto", "usos": "Subproducto de Trigo usado en el proceso de preparación de pan integral, principalmente en los semas. ", "presentaciones": "Rafia: 30kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Rafia: 25 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/parayas.png"), "marca":"Salvado Dietetico", "tipo_harina": "Subproducto", "usos": "Recomendado para la elaboración de galletas, es conocido para usos con fines medicinales como control de colesterol y mejorar la digestión.", "presentaciones": "Rafia: 20kg 40kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Rafia: 20 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/parayas.png"), "marca":"Semola de Trigo Semola (Crema de Trigo)", "tipo_harina": "Semola", "usos": "Recomendado para la fabricación de platos tradicionales como migas de sémola del cuscús oriental, además de ser la base de la elaboración industrial de las pastas secas de la cocina italiana.", "presentaciones": "Rafia: 20kg Bolsa Zip-lock: 300gr", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Rafia: 20 kg", "url": ""},
{ "img_src": require("../../../../assets/images/sitio/6_productos_socios/parayas/parayas.png"), "marca":"Salvado Forrajero", "tipo_harina": "Subproducto", "usos": "Recomendado para la fabricación de como alimento para la engorda de animales, cerdos y ganado principalmente.", "presentaciones": "Rafia: 40kg", "comentarios":"Por requerimiento de NOM-036 estandarizacion a presentacion de Rafia: 25kg", "url": ""},
]
export const productosMarcas = (marca) => {

    let empresa = [];

    switch(marca){
        case 'anahuac': empresa = anahuac; break;
        case 'chiapas': empresa = chiapas; break;
        case 'deusto': empresa = deusto; break;
        case 'elizondo': empresa = elizondo; break;
        case 'guadalupe': empresa = guadalupe; break;
        case 'ho': empresa = ho; break;
        case 'tlanepantla': empresa = tlanepantla; break;
        case 'moderna': empresa = moderna; break;
        case 'trimex': empresa = trimex; break;
        case 'trimex_molinera': empresa = trimex_molinera; break;
        case 'trimex_espiga': empresa = trimex_espiga; break;
        case 'real_altena': empresa = real_altena; break;
        case 'parayas': empresa = parayas; break;
        default: empresa = []; break;
    }

    return empresa;

}